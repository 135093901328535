<template>
  <div class="content">
    <div class="top">
      <div>欢迎您的到来</div>
      <span>横琴粤澳深度合作区人力资源服务产业园</span>
    </div>
    <input type="number" v-model="phone" placeholder="输入手机号" />
    <input type="password" v-model="password" placeholder="输入登录密码" />
    <div class="yzm">
      <input type="text" v-model="yzm" placeholder="输入验证码" />
      <canvas id="s-canvas" @click="upcode" :width="contentWidth" :height="contentHeight"></canvas>
    </div>
    <div class="uppass">
      <div style="font-size:12px;" @click="gouppass">&nbsp;&nbsp;忘记密码</div>
      <div class="up" @click="upcode">换一张</div>
    </div>
    <div class="submit" @click="goHome">登 录</div>
    <!-- <div>{{text+code}}</div> -->
    <div class="bottom">
      没有账号？点这里
      <span @click="goRegister">注册</span>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getWechatCode, login } from '../../api/user'
export default {
  data() {
    return {
      code: '',
      password: '',
      phone: '',
      text: '',
      identifyCode: '',
      fontSizeMin: 25,
      fontSizeMax: 35,
      backgroundColorMin: 200,
      backgroundColorMax: 220,
      dotColorMin: 60,
      dotColorMax: 120,
      contentWidth: 90,
      contentHeight: 38,
      yzm: '',
      openid: ''
    }
  },
  watch: {
    identifyCode() {
      this.drawPic()
    }
  },
  created() {
    this.openid = localStorage.getItem('openid')
    this.makeCode('1234567890', 4)
    console.log(window.location)
    this.code = String(window.location)
      .split('?')[1]
      .split('&')[0]
      .split('=')[1]
  },
  mounted() {
    this.getWechatCode()
    this.drawPic()
  },
  methods: {
    gouppass() {
      this.$router.push('/password')
    },
    upcode() {
      this.identifyCode = ''
      this.makeCode('1234567890', 4)
      this.drawPic()
    },
    goRegister() {
      this.$router.push('/register')
    },
    async getWechatCode() {
      if (this.openid) {
        return
      }
      const { data } = await getWechatCode({
        code: this.code
      })
      if (data.code === 200) {
        localStorage.setItem('openid', data.data.openid)
        this.openid = data.data.openid
      } else {
        this.text = data
      }
    },
    async goHome() {
      if (!this.phone) {
        Toast.fail('请填写手机号')
      } else if (!this.password) {
        Toast.fail('请输入密码')
      } else if (!this.yzm) {
        Toast.fail('请输入验证码')
      } else if (this.yzm !== this.identifyCode) {
        Toast.fail('验证码输入有误')
        this.yzm = ''
        this.identifyCode = ''
        this.makeCode('1234567890', 4)
        this.drawPic()
      } else {
        const data = await login({
          password: this.password,
          phone: this.phone,
          // openid: this.openid,
          openid: 1111
        })
        console.log(data)
        if (data.data.code === 200) {
          localStorage.setItem('token', data.headers.authorization)
          this.$router.push('/venue')
        } else {
          Toast.fail(data.data.msg)
        }
      }
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 生成一个随机的颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max)
      const g = this.randomNum(min, max)
      const b = this.randomNum(min, max)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    drawPic() {
      const canvas = document.getElementById('s-canvas')
      const ctx = canvas.getContext('2d')
      ctx.textBaseline = 'bottom'
      // 绘制背景
      ctx.fillStyle = this.randomColor(
        this.backgroundColorMin,
        this.backgroundColorMax
      )
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
      // 绘制文字
      for (let i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i)
      }
      this.drawLine(ctx)
      this.drawDot(ctx)
    },
    drawText(ctx, txt, i) {
      ctx.fillStyle = this.randomColor(50, 160) // 随机生成字体颜色
      ctx.font =
        this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei' // 随机生成字体大小
      const x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
      const y = this.randomNum(this.fontSizeMax, this.contentHeight - 5)
      var deg = this.randomNum(-30, 30)
      // 修改坐标原点和旋转角度
      ctx.translate(x, y)
      ctx.rotate((deg * Math.PI) / 180)
      ctx.fillText(txt, 0, 0)
      // 恢复坐标原点和旋转角度
      ctx.rotate((-deg * Math.PI) / 180)
      ctx.translate(-x, -y)
    },
    drawLine(ctx) {
      // 绘制干扰线
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(100, 200)
        ctx.beginPath()
        ctx.moveTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        )
        ctx.lineTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        )
        ctx.stroke()
      }
    },
    drawDot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 30; i++) {
        ctx.fillStyle = this.randomColor(0, 255)
        ctx.beginPath()
        ctx.arc(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight),
          1,
          0,
          2 * Math.PI
        )
        ctx.fill()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  .uppass {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 68vw;
    margin-left: calc(16vw + 2px);
    color: #f8892f;
    margin-top: 5px;
  }
  .bottom {
    text-align: center;
    font-size: 12px;
    color: #555f70;
    span {
      color: #f8892f;
    }
  }
  .submit {
    border: none;
    outline: none;
    margin-bottom: 50px;
    width: 68vw;
    margin-left: 16vw;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    background-color: #f8892f;
    font-size: 18px;
    margin-top: 30px;
  }
  .up {
    font-size: 10px;
    color: #a6a9ba;
    // width: 68vw;
    // text-align: right;
    // margin-top: 5px;
  }
  input {
    border: none;
    outline: none;
    width: 68vw;
    margin-left: 16vw;
    height: 44px;
    border-radius: 22px;
    background-color: #eeeff3;
    margin-bottom: 20px;
    text-indent: 24px;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: #a6a9ba;
  }
  input:-ms-input-placeholder {
    font-size: 14px;
    color: #a6a9ba;
  }
  width: 100%;
  height: 100vh;
  background-color: #fbfbfb;
  padding-top: 14vh;
  box-sizing: border-box;
  .top {
    margin-bottom: 53px;
    font-size: 21px;
    color: #333950;
    text-align: center;
    div {
      font-weight: bold;
    }
    span {
      font-size: 12px;
      color: #a0a5bb;
    }
  }
  .yzm {
    width: 68vw;
    margin-left: 16vw;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      margin: 0%;
      width: 55%;
      padding: 0%;
    }
    div {
      width: 35%;
      height: 38px;
      background-color: #eeeff3;
    }
  }
}
</style>
